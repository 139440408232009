import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import useStores from "@hooks/useStores";
import useError from "@hooks/useError";

import Hidden from "@material-ui/core/Hidden";

import { stores, styles } from "./.config";

import * as Steps from "./Steps";
import Dialog from "@material-ui/core/Dialog/Dialog";
import useStyles from "@hooks/useStyles";
import LeadFunnel from "./LeadFunnel";

const LeadFunnelModal = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(({ provider, zipcode, service, base64EncodedAnswerKeys, midtail }) => {
	const classes = useStyles("LeadFunnel");
	const [ErrorTemplate, onError] = useError();
	const { leadFunnelStore, leadFunnelModalStore } = useStores();

	useEffect(() => {
		if (leadFunnelModalStore.show === true) {
			leadFunnelStore
				.init({
					provider,
					zipcode,
					service,
					midtail,
					isModal: true,
					Steps,
					leadActionType: leadFunnelModalStore.leadActionType,
					base64EncodedAnswerKeys,
				})
				.then()
				.catch(onError);
		}
	}, [leadFunnelModalStore.show]);

	const onKeyPress = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			leadFunnelStore?.currentNextHandler();
		}
	};

	return (
		<React.Fragment>
			<Hidden xsDown>
				<Dialog
					id={"LeadFunnelMobile"}
					onKeyPress={onKeyPress}
					classes={{ paper: classes.dialogPaper }}
					scroll={"paper"}
					fullScreen={false}
					fullWidth={true}
					maxWidth={leadFunnelModalStore.maxWidth}
					open={leadFunnelModalStore.show}
					onClose={leadFunnelModalStore.onClose}
					aria-labelledby="Lead form"
					aria-describedby="Lead form"
				>
					<LeadFunnel
						base64EncodedAnswerKeys={base64EncodedAnswerKeys}
						provider={provider}
						service={service}
						isModal={true}
					/>
				</Dialog>
			</Hidden>
			<Hidden smUp>
				<Dialog
					id={"LeadFunnel"}
					onKeyPress={onKeyPress}
					style={{ overflow: "hidden" }}
					scroll={"paper"}
					fullScreen={true}
					fullWidth={true}
					maxWidth={leadFunnelModalStore.maxWidth}
					open={leadFunnelModalStore.show}
					onClose={leadFunnelModalStore.onClose}
					aria-labelledby="Lead form"
					aria-describedby="Lead form"
				>
					<LeadFunnel
						base64EncodedAnswerKeys={base64EncodedAnswerKeys}
						provider={provider}
						service={service}
						isModal={true}
					/>
				</Dialog>
			</Hidden>
			<ErrorTemplate />
		</React.Fragment>
	);
});

LeadFunnelModal.propTypes = {
	provider: PropTypes.object,
	zipcode: PropTypes.string,
	service: PropTypes.object,
};

export default LeadFunnelModal;
